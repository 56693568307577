<template>
  <div id="reset">
    <h1>Passwort zurücksetzen</h1>

    <div>
      <TextInput v-for="field in fields" :input-placeholder="field.placeholder"
                 class="textInput" :text-field-type="field.type" v-model:input-text="field.text"
                 :input-title="field.label" :validation-function="field.validFunction" :key="field.id"
                 :ref="(el)=>setRegisterRefs(el,field.id)"
                 :do-validation="field.doValidation" :input-name="field.id"
                 :password-requirements="field.passwordRequirements"
                 :prevent-copy-paste="field.preventCopyPaste"/>


      <PlainButton :button-function="()=>submitPassword()">
        Passwort zurücksetzen
      </PlainButton>

    </div>
    <transition name="error-show">
      <ErrorPop :error-text="errorMessage" v-if="errorType==='alert-danger'">
      </ErrorPop>
    </transition>
    <transition name="dialog">
      <SuccessDialog dialog-title="Erfolg!" button-text="Zum Login" v-if="showSuccessDialog"
                     :confirm-function="()=>{this.$router.push('/login')}">
        Dein Passwort wurde erfolgreich zurückgesetzt.
      </SuccessDialog>
    </transition>

    <transition name="dialogBackground">
      <div class="dialogBackground"
           v-if="showSuccessDialog"
           ></div>
    </transition>
  </div>
</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton.vue";
import TextInput from "@/components/Inputs/TextInput.vue";
import {userService} from "@/services";
import {mapActions, mapState} from "vuex";
import ErrorPop from "@/components/Popups/ErrorPop.vue";
import SuccessDialog from "@/components/Dialogs/SuccessDialog.vue";

export default {
  name: "PasswordResetFrom",
  components: {SuccessDialog, ErrorPop, TextInput, PlainButton},
  inheritAttrs: false,
  methods: {
    ...mapActions('alert', ['clear']),
    setRegisterRefs(el, name) {
      if (el) {
        this.refs[name] = el;
      }
    },
    validateTexts() {
      let isValid = true;
      this.fields.forEach(field => {
        this.refs[field.id].validate(field.text)
        isValid = !this.refs[field.id].isInvalid && isValid;
      });
      return isValid;
    },
    async submitPassword() {
      if (this.validateTexts()) {
        await this.clear();
        let value = await userService.sendNewPassword(this.$route.query.prt ?? "", {'password': this.password})
        if (value === null) {
          this.showSuccessDialog = true;
        }
      }
    }
  },
  computed: {
    password() {
      return this.fields.find(value => value.id === 'password').text
    },
    ...mapState('alert', {
      errorMessage: 'message',
      errorType: 'type'
    })
  },
  data() {
    return {
      refs: {},
      fields: [
        {
          label: 'Neues Passwort',
          text: '',
          id: 'password',
          type: 'password',
          preventCopyPaste: true,
          passwordRequirements: [
            {
              id: 1, helpText: "Groß und Kleinbuchstaben (keine Umlaute)", validationFunction: (text) => {
                return (/[a-z]/.test(text)) && (/[A-Z]/.test(text))
              }
            },
            {
              id: 2, helpText: "Min. 8 Zeichen", validationFunction: (text) => {
                return text.length >= 8
              }
            },
            {
              id: 3, helpText: "Ein Sonderzeichen", validationFunction: (text) => {
                return (/[^\w]/.test(text))
              }
            },
            {
              id: 4, helpText: "Eine Zahl", validationFunction: (text) => {
                return (/\d/.test(text))
              }
            }
          ],
          validFunction: function (text) {
            if (text.length < 8 || text.length === 0) {
              return {
                isInvalid: true,
                errorText: 'Passwort zu kurz'
              }
            } else if (!(/\d/.test(text))) {
              return {
                isInvalid: true,
                errorText: 'Passwort muss eine Zahl beinhalten'
              }
            } else if (!(/[a-z]/.test(text)) || !(/[A-Z]/.test(text))) {
              return {
                isInvalid: true,
                errorText: 'Passwort muss einen Groß- und einen Kleinbuchstaben beinhalten'
              }
            } else if (!(/[^w]/.test(text))) {
              return {
                isInvalid: true,
                errorText: 'Passwort muss ein Sonderzeichen beinhalten'
              }
            } else {
              return {
                isInvalid: false,
                errorText: ''
              };
            }
          },
          doValidation: true
        },
        {
          label: 'Passwort wiederholen',
          text: '',
          id: 'password',
          type: 'password',
          validFunction: (text) => {
            return !(this.password === text) ?
                {
                  isInvalid: true,
                  errorText: 'Passwort muss übereinstimmen'
                } : {
                  isInvalid: false,
                  errorText: ''
                }
          },
          doValidation: true,
          preventCopyPaste: true
        },
      ],
      showSuccessDialog: false
    }
  }


}
</script>

<style scoped lang="scss">
@import "src/styles/themes.scss";

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 4em;
  line-height: 1em;
  margin-bottom: 32px;
  @include theme() {
    color: theme-get('header-1')
  }

}

.textInput {
  margin-bottom: 48px;
}

#reset {
  padding: 0 32px;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-height: max-content;
  flex-direction: column;
}

.dialog-enter-active, .dialog-leave-active {
  transition: 0.25s ease;
}
.dialog-enter-to, .dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.dialog-enter-from, .dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}

.dialogBackground-enter-active, .dialogBackground-leave-active {
  transition: opacity 0.25s;
}
.dialogBackground-enter-from, .dialogBackground-leave-to {
  opacity: 0;
}

.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}


@media screen and (max-width: 768px) {
  .header {
    max-width: none;
  }
  h1 {
    font-size: 3em;
  }
  p {
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {

  h1 {
    font-size: 2em;
  }
  p {
    font-size: 1em;
  }
}
</style>
